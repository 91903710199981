import { NotificationsApi } from '~/api';
import { action, makeObservable, observable } from '~/modules';

export default class NotificationsStore {
  @observable
  notificationList: ListResponse<PushNotification> = {};

  constructor() {
    makeObservable(this);
  }

  @action
  getNotifications = async (
    values: GetPushNotificationsParams,
  ): Promise<void> => {
    this.notificationList = await NotificationsApi.getNotifications(values);
  };

  @action
  createNotification = async (
    values: CreatePushNotificationRequestParams,
  ): Promise<void> => {
    await NotificationsApi.createNotification(values);
  };

  @action
  deleteNotification = async (
    values: DeletePushNotificationParams,
  ): Promise<void> => {
    await NotificationsApi.deleteNotification(values);
  };
}
