import { NotificationTypes } from '../enums';

export const notificationsColumns = [
  'Titulo',
  '',
  'Data de envio',
  'Data de criação',
  'Status',
  '',
];

export const oneHourInSeconds = 3600;

export const messageMaxLength = 120;

export const titleMaxLength = 50;

export const minimumHoursAhead = 2;

export const notificationTypes: SelectProps[] = [
  { label: 'Notificação agendada', value: NotificationTypes.SCHEDULED },
  {
    label: 'Notificação imediata',
    value: NotificationTypes.IMMEDIATE,
  },
];
