import { FC, useEffect, useState } from 'react';
import { ModalCreateNotification } from '~/components';
import { observer } from '~/modules';
import {
  AlertMessages,
  NotificationTypes,
  shortDateTimeToDateTimeWithMinutes,
  showAlert,
  useStores,
} from '~/utils';
import Notifications from './Notifications';

export const NotificationsContainer: FC = () => {
  const {
    notifications: {
      getNotifications,
      notificationList,
      createNotification,
      deleteNotification,
    },
  } = useStores();

  const [isNewNotificationModalVisible, setIsNewNotificationModalVisible] =
    useState(false);
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(true);

  const toggleNewNotificationModalVisibility = () =>
    setIsNewNotificationModalVisible(
      (isNewNotificationModalVisible) => !isNewNotificationModalVisible,
    );

  const handleCreateNotification = async (
    values: CreatePushNotificationParams,
  ) => {
    try {
      const payload = {
        message: values.message,
        title: values.title,
        deliveryAt:
          values.type === NotificationTypes.SCHEDULED && values.deliveryAt
            ? shortDateTimeToDateTimeWithMinutes(values.deliveryAt)
            : undefined,
      };

      await createNotification(payload);
      await handleGetNotifications({});
      toggleNewNotificationModalVisibility();
      showAlert({
        message: AlertMessages.SUCCESS_CREATE_NOTIFICATION,
        type: 'success',
      });
    } catch ({ message }) {
      showAlert({ message });
    }
  };

  const handleGetNotifications = async (values: GetPushNotificationsParams) => {
    setIsNotificationsLoading(true);
    try {
      await getNotifications(values);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setIsNotificationsLoading(false);
    }
  };

  const handleDeleteNotification = async (
    values: DeletePushNotificationParams,
  ) => {
    try {
      await deleteNotification(values);
      await handleGetNotifications({});
      showAlert({
        message: AlertMessages.SUCCESS_DELETE_NOTIFICATION,
        type: 'success',
      });
    } catch ({ message }) {
      showAlert({ message });
    }
  };

  useEffect(() => {
    handleGetNotifications({});
  }, []);

  return (
    <>
      <Notifications
        isNotificationsLoading={isNotificationsLoading}
        notificationList={notificationList}
        onPressRegisterUser={toggleNewNotificationModalVisibility}
        onChangePage={(page) => handleGetNotifications({ page })}
        onDeleteNotification={handleDeleteNotification}
      />
      <ModalCreateNotification
        isOpen={isNewNotificationModalVisible}
        handleClose={toggleNewNotificationModalVisibility}
        handleFormSubmit={handleCreateNotification}
      />
    </>
  );
};

export default observer(NotificationsContainer);
