import { FC } from 'react';
import {
  faCircleSmall,
  faIdCardSolid,
  faLockSolid,
  faMemoCircleInfo,
  faPlus,
} from '~/assets';
import {
  UploadRoles,
  UploadRolesPt,
  UploadStatus,
  UploadStatusPt,
  formatHourMinuteSecondDate,
  // integerToCurrency,
  parseEnumToPt,
  positionsList,
} from '~/utils';
import If from '../If';
import StatusIndicator from '../StatusIndicator';
import {
  Card,
  CreatedAt,
  IconCard,
  IconHiddenName,
  IconSearch,
  Name,
  Row,
  RowButtonSearch,
  SearchIconBox,
  Type,
} from './styles';

type Props = {
  name?: string;
  type?: UploadRoles;
  status?: UploadStatus;
  createdAt?: Date;
  totalAmount?: number;
  showInfo?: boolean;
  sideAccount?: boolean;
  onPress?(): void;
  onPressToggleSwitch?(isActive: boolean): Promise<void> | void;
};

const UploadRow: FC<Props> = ({
  name = '',
  showInfo = true,
  status,
  createdAt,
  // totalAmount,
  type,
  sideAccount,
  onPress = () => {},
  onPressToggleSwitch,
}) => (
  <Card>
    <Row>
      <IconCard icon={showInfo ? faIdCardSolid : faLockSolid} />
      <If condition={!showInfo}>
        {positionsList.map((value) => (
          <IconHiddenName key={value} icon={faCircleSmall} />
        ))}
      </If>
      <If condition={showInfo}>
        <Name>{name}</Name>
      </If>
    </Row>
    <Row>
      <Type>{parseEnumToPt(type, UploadRolesPt)}</Type>
    </Row>
    {/* <Row>{integerToCurrency(totalAmount || 0)}</Row> */}
    <Row>
      <StatusIndicator status={parseEnumToPt(status, UploadStatusPt)} />
    </Row>
    <Row>
      <CreatedAt>{formatHourMinuteSecondDate(createdAt)}</CreatedAt>
    </Row>

    <If condition={!onPressToggleSwitch}>
      <RowButtonSearch>
        <SearchIconBox id="onPress_product" onPress={onPress}>
          <IconSearch icon={sideAccount ? faPlus : faMemoCircleInfo} />
        </SearchIconBox>
      </RowButtonSearch>
    </If>
  </Card>
);

export default UploadRow;
