export enum NotificationTypes {
  IMMEDIATE = 'IMMEDIATE',
  SCHEDULED = 'SCHEDULED',
}

export enum NotificationStatus {
  SCHEDULED = 'SCHEDULED',
  SUCCESS = 'SUCCESS',
}

export enum NotificationStatusPt {
  SCHEDULED = 'AGENDADA',
  SUCCESS = 'ENVIADA',
}
