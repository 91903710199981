import {
  Icon as MIcon,
  Typography,
  getTheme,
  pxToRem,
  styled,
} from '~/modules';
import Button from '../Button';
import Modal from '../Modal';
import TextInput from '../TextInput';

const boxShadowZ4 = getTheme('boxShadow.z4');
const backgroundZ3 = getTheme('background.z3');
const backgroundZ4 = getTheme('background.z4');

const dangerDark = getTheme('danger.dark');

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXxl = getTheme('spacing.xxl');

const themeRadiusCard = getTheme('themeRadius.card');
const borderRadiusSm = getTheme('borderRadius.sm');

const textContrast = getTheme('text.contrast');

export const ModalWrapper = styled.div`
  padding: 100px;
  height: auto;
  overflow: auto;
  background: red;
`;
export const Wrapper = styled(Modal)`
  flex-direction: column;
  width: ${pxToRem(959)};
  max-width: 90%;
  background-color: ${backgroundZ3};
  ${boxShadowZ4};
`;

export const Content = styled.div`
  flex-direction: column;
  width: 100%;
  padding: ${spacingXxl}px;
`;

export const Row = styled.div`
  flex: 1;
  gap: ${spacingMd}px;
`;
export const Col = styled.div`
  flex: 1;
`;

export const ErrorsIcon = styled(MIcon).attrs({
  fontSize: pxToRem(20),
})`
  color: ${textContrast};
  margin-right: ${spacingSm}px;
  background: ${dangerDark};
  padding: ${spacingSm}px;
  border-radius: ${borderRadiusSm}px;
`;

export const ErrorsTitle = styled(Typography)`
  color: ${dangerDark};
  margin: ${spacingLg}px 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Errors = styled.div`
  flex: 1;
  flex-direction: column;
  gap: ${spacingSm}px;
`;

export const Error = styled.div`
  flex: 1;
  padding: ${spacingMd}px;
  border-radius: ${borderRadiusSm}px;
  background: ${backgroundZ4};
`;

export const ErrorKey = styled(Typography).attrs({
  variant: 'sm',
})`
  font-weight: 500;
  color: ${dangerDark};
`;

export const ErrorValue = styled(Typography).attrs({
  variant: 'sm',
})`
  margin-left: ${spacingSm}px;
`;

export const Input = styled(TextInput)`
  margin-bottom: ${spacingMd}px;
`;

export const ButtonComponent = styled(Button)`
  width: ${pxToRem(250)};
  height: ${pxToRem(51)};
`;

export const Icon = styled(MIcon).attrs({
  fontSize: pxToRem(20),
})`
  margin-right: ${spacingMd}px;
`;

export const Footer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: ${pxToRem(106)};
  background-color: ${backgroundZ4};
  border-bottom-left-radius: ${themeRadiusCard}px;
  border-bottom-right-radius: ${themeRadiusCard}px;
  gap: ${spacingMd}px;
`;
