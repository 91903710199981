import { FC } from 'react';
import { faXmark } from '~/assets';
import { If, OptionButton } from '~/components';
import {
  TransactionType,
  fillStringIfEmpty,
  formatTimestampToDate,
  formatToCurrency,
  getInstallments,
  maskCpfOrCnpj,
} from '~/utils';
import HeaderModal from '../HeaderModal';
import TransactionTypeIndicator from '../TransactionTypeIndicator';
import {
  Amount,
  Card,
  CardValue,
  Column,
  Columns,
  Content,
  Description,
  Installments,
  Label,
  Prefix,
  Row,
  Title,
  TitleColumn,
  TopInfoBox,
  Value,
  ValueBox,
  ValueMetaData,
  Wrapper,
  WrapperAmount,
  WrapperItemMetaData,
  WrapperValue,
} from './styles';

type Props = {
  isOpen: boolean;
  transactionDetail: Transaction;
  handleClose(): void;
  onCancelTransaction(): void;
};

const ModalTransactionDetails: FC<Props> = ({
  isOpen,
  transactionDetail,
  handleClose,
  onCancelTransaction,
}) => {
  const {
    id,
    source,
    destination,
    amount,
    transactionId,
    transactionType,
    metadata,
    createdAt,
  } = transactionDetail;

  const installments = getInstallments(transactionDetail);

  return (
    <Wrapper isOpen={isOpen} onClose={handleClose}>
      <HeaderModal title="Detalhes da Transação" onPressClose={handleClose} />
      <Content>
        <TopInfoBox>
          <ValueBox>
            <Title>Valor da transação</Title>
            <WrapperAmount>
              <Amount>
                <Prefix>R$</Prefix>
                {formatToCurrency(amount, false)}
              </Amount>

              <If condition={!!installments}>
                <Installments>
                  Parcela {installments?.installmentNumber} de{' '}
                  {installments?.totalInstallments}
                </Installments>
              </If>
            </WrapperAmount>
            <TransactionTypeIndicator type={transactionType} />
          </ValueBox>
          <Column>
            {transactionType === TransactionType.CASH_OUT && (
              <WrapperValue>
                <OptionButton
                  id="btn_cancel_transaction"
                  label="Cancelar Transação"
                  icon={faXmark}
                  onPress={onCancelTransaction}
                />
              </WrapperValue>
            )}
            <WrapperValue>
              <Label>ID</Label>
              <Value>{id}</Value>
            </WrapperValue>
            <WrapperValue>
              <Label>TX ID</Label>
              <Value>{transactionId}</Value>
            </WrapperValue>
            <WrapperValue>
              <Label>DATA</Label>
              <Value>{formatTimestampToDate(createdAt)}</Value>
            </WrapperValue>
          </Column>
        </TopInfoBox>

        <Row>
          <Column>
            <Card>
              <Title>Origem</Title>
              <WrapperValue>
                <Label>NOME</Label>
                <Value>{fillStringIfEmpty(source?.fullName)}</Value>
              </WrapperValue>
              <WrapperValue>
                <Label>DOCUMENTO</Label>
                <Value>
                  {fillStringIfEmpty(maskCpfOrCnpj(source?.document))}
                </Value>
              </WrapperValue>
            </Card>
          </Column>
          <Column>
            <Card>
              <Title>Destino</Title>
              <WrapperValue>
                <Label>NOME</Label>
                <Value>{fillStringIfEmpty(destination?.fullName)}</Value>
              </WrapperValue>
              <WrapperValue>
                <Label>DOCUMENTO</Label>
                <Value>
                  {fillStringIfEmpty(maskCpfOrCnpj(destination?.document))}
                </Value>
              </WrapperValue>
            </Card>
          </Column>
        </Row>

        <Row>
          <Card>
            <Title>Informações Adicionais</Title>
            <Columns>
              <TitleColumn>Descrição</TitleColumn>
              <TitleColumn>Valor</TitleColumn>
            </Columns>
            {metadata?.map(({ key, value }, index) => {
              return (
                <WrapperItemMetaData key={index}>
                  <Description>{key}</Description>
                  <CardValue>
                    <ValueMetaData>{value}</ValueMetaData>
                  </CardValue>
                </WrapperItemMetaData>
              );
            })}
          </Card>
        </Row>
      </Content>
    </Wrapper>
  );
};

export default ModalTransactionDetails;
