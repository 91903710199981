import { FC } from 'react';
import { faXmark } from '~/assets';
import { ButtonClose, IconClose, Title, Wrapper } from './styles';

type Props = {
  title: string;
  className?: string;
  onPressClose?(): void;
};

const HeaderModal: FC<Props> = ({ title, className, onPressClose }) => (
  <Wrapper className={className}>
    {onPressClose && (
      <ButtonClose id="btn_close" onPress={onPressClose}>
        <IconClose icon={faXmark} />
      </ButtonClose>
    )}
    <Title>{title}</Title>
  </Wrapper>
);

export default HeaderModal;
