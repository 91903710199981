import { Modal, getTheme, styled } from '~/modules';

const backgroundZ4 = getTheme('background.z4');
const themeRadiusCard = getTheme('themeRadius.card');
const boxShadowZ4 = getTheme('boxShadow.z4');

export const Wrapper = styled(Modal).attrs({
  style: {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.3)',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      backdropFilter: 'blur(8px)',
      margin: 'auto',
      overflow: 'auto',
      padding: 48,
    },
    content: {
      margin: 'auto',
    },
  },
})`
  display: flex;
  background-color: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;
  width: max-content;
  height: max-content;
  align-items: center;
  justify-content: center;
  ${boxShadowZ4};
`;
