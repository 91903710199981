import { FC } from 'react';
import { faPlus } from '~/assets';
import { NotificationsRow, PaginatedList, TitlePage } from '~/components';
import { notificationsColumns } from '~/utils';
import {
  ButtonRegisterUser,
  ContentBox,
  IconPlus,
  TopBox,
  Wrapper,
} from './styles';

type Props = {
  isNotificationsLoading: boolean;
  notificationList: ListResponse<PushNotification>;
  onPressRegisterUser(): void;
  onChangePage(page: number): void;
  onDeleteNotification(values: DeletePushNotificationParams): Promise<void>;
};

const Notifications: FC<Props> = ({
  notificationList,
  isNotificationsLoading,
  onPressRegisterUser,
  onChangePage,
  onDeleteNotification,
}) => (
  <Wrapper titlePage="Notificações">
    <ContentBox>
      <TopBox>
        <TitlePage totalElements={notificationList.paging?.totalElements}>
          Notificações
        </TitlePage>
        <ButtonRegisterUser
          id="btn_modal_register_user"
          onPress={onPressRegisterUser}
          secondary
        >
          <IconPlus icon={faPlus} />
          Criar notificação
        </ButtonRegisterUser>
      </TopBox>

      <PaginatedList
        columns={notificationsColumns}
        list={notificationList.content}
        totalPages={notificationList.paging?.totalPages}
        currentPage={notificationList.paging?.page}
        isLoading={isNotificationsLoading}
        onChangePage={onChangePage}
        renderList={(notification) => (
          <NotificationsRow
            id={notification.id}
            createdAt={notification.createdAt}
            deliveryAt={notification.deliveryAt}
            title={notification.title}
            message={notification.message}
            status={notification.status}
            onDelete={onDeleteNotification}
            key={notification.id}
          />
        )}
        titleEmptyData="Sem notificações cadastradas"
      />
    </ContentBox>
  </Wrapper>
);

export default Notifications;
