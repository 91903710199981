import { ListPagination, ResponseError } from '~/utils';
import request from '../request';

export const getNotifications = async ({
  page = ListPagination.INITIAL_PAGE,
  size = ListPagination.SIZE,
}: GetPushNotificationsParams): Promise<ListResponse<PushNotification>> => {
  try {
    const { data } = await request.get('/push/notifications/history', {
      params: {
        page,
        size,
      },
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const createNotification = async (
  values: CreatePushNotificationRequestParams,
): Promise<void> => {
  try {
    await request.post(`/push/notifications`, values);
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const deleteNotification = async (
  values: DeletePushNotificationParams,
): Promise<void> => {
  try {
    await request.delete(`/push/notifications/${values.id}`);
  } catch (error) {
    throw new ResponseError(error);
  }
};
