import { FormValidator } from '~/modules';
import {
  NotificationTypes,
  messageMaxLength,
  minimumHoursAhead,
  shortDateTimeToMomentDate,
  sumToCurrentDate,
  titleMaxLength,
} from '~/utils';

export const validationSchema = FormValidator.object().shape({
  title: FormValidator.string()
    .required('Campo obrigatório')
    .max(
      titleMaxLength,
      `O titulo deve ter no máximo ${titleMaxLength} caracteres`,
    ),
  message: FormValidator.string()
    .required('Campo obrigatório')
    .max(
      messageMaxLength,
      `A mensagem deve ter no máximo ${messageMaxLength} caracteres`,
    ),
  type: FormValidator.string().required('Campo obrigatório'),
  deliveryAt: FormValidator.string()
    .nullable()
    .when('type', {
      is: NotificationTypes.SCHEDULED,
      then: () =>
        FormValidator.string()
          .required('Campo obrigatório para notificações agendadas')
          .test('valid-date', 'A data informada é inválida', (value) => {
            const date = shortDateTimeToMomentDate(value);
            return date.isValid();
          })
          .test(
            'min-date',
            `A notificação só pode ser agendada com no mínimo ${minimumHoursAhead} horas de antecedência`,
            (value) => {
              const date = shortDateTimeToMomentDate(value);
              const minDate = sumToCurrentDate(minimumHoursAhead, 'hours');
              return date.isAfter(minDate);
            },
          ),
    }),
});

export const initialValues: CreatePushNotificationParams = {
  title: '',
  message: '',
  deliveryAt: '',
  type: NotificationTypes.IMMEDIATE,
};
