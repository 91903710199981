export const getInstallments = (item) => {
  const metadata = item?.metadata?.reduce((acc, { key, value }) => {
    if (
      key === 'total_installments' ||
      key === 'installment_number' ||
      key === 'transaction_date'
    ) {
      acc[key] = value;
    }
    return acc;
  }, {});

  if (
    !metadata?.total_installments ||
    !metadata?.installment_number ||
    !metadata?.transaction_date
  ) {
    return undefined;
  }

  return {
    totalInstallments: metadata.total_installments,
    installmentNumber: metadata.installment_number,
    transactionDate: metadata.transaction_date,
  };
};
