import styled from 'styled-components';
import { Icon, getTheme, pxToRem } from '~/modules';
import Button from '../Button';
import Typography from '../Typography';

const backgroundZ4 = getTheme('background.z4');
const themeRadiusCard = getTheme('themeRadius.card');
const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const brandPrimaryContrast = getTheme('brand.primary.contrast');
const borderRadiusXl = getTheme('borderRadius.xl');

export const Col = styled.div`
  align-items: center;
  flex: 1;
  margin-right: ${spacingSm}px;
`;

export const Card = styled.div`
  display: grid;
  grid-template-columns: 5fr 2fr 2fr 2fr 1fr;
  align-items: center;
  width: 100%;
  padding: ${spacingLg}px;
  background: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;
  & + & {
    margin-top: ${spacingSm}px;
  }
`;

export const HighlightedText = styled(Typography).attrs({ variant: 'md' })`
  flex: 1;
  font-weight: bold;
  margin-right: ${spacingMd}px;
  margin-bottom: ${spacingSm}px;
`;

export const Text = styled(Typography)``;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${spacingLg}px;
`;

export const ColDeleteButton = styled(Col)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const DeleteButton = styled(Button)`
  width: ${pxToRem(56)};
  height: ${pxToRem(56)};
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  border-radius: ${borderRadiusXl}px;
  cursor: pointer;
`;

export const DeleteIcon = styled(Icon)`
  font-size: ${pxToRem(24)};
  color: ${brandPrimaryContrast};
  font-weight: 900;
`;
