import { TransactionsApi } from '~/api';
import { action, makeObservable, observable, persist } from '~/modules';

export default class TransactionStore {
  @observable
  transactionsList: ListResponse<Transaction> = {};

  @observable
  schedulingList: ListResponse<Scheduling> = {};

  @persist('object')
  @observable
  transaction: Transaction = {} as Transaction;

  constructor() {
    makeObservable(this);
  }

  @action
  getAccountTransactions = async (
    values: GetTransactionsProps,
  ): Promise<void> => {
    this.transactionsList =
      await TransactionsApi.getAccountTransactions(values);
  };

  @action
  getAccountScheduling = async ({
    accountId,
  }: GetSchedulingProps): Promise<void> => {
    this.schedulingList = await TransactionsApi.getAccountScheduling({
      accountId,
    });
  };

  @action
  setAccountTransactions = (
    transactionsList: ListResponse<Transaction>,
  ): void => {
    this.transactionsList = transactionsList;
  };

  @action
  getTransactionDetails = async (
    values: GetTransactionDetailsProps,
  ): Promise<void> => {
    this.transaction = await TransactionsApi.getTransactionDetails(values);
  };

  @action
  cancelTransaction = async ({
    transactionId,
  }: CancelTransactionProps): Promise<void> => {
    await TransactionsApi.cancelTransaction({ transactionId });
  };

  @action
  generatePDF = async ({
    accountId,
    startDate,
    endDate,
  }: GeneratePDFProps): Promise<any> => {
    return TransactionsApi.generatePDF({
      accountId,
      startDate,
      endDate,
    });
  };
}
