import { FC } from 'react';
import { faTrash } from '~/assets';
import {
  NotificationStatus,
  NotificationStatusPt,
  formatHourMinuteSecondDate,
  parseEnumToPt,
} from '~/utils';
import StatusIndicator from '../StatusIndicator';
import {
  Card,
  Col,
  ColDeleteButton,
  ContentWrapper,
  DeleteButton,
  DeleteIcon,
  HighlightedText,
  Text,
} from './styles';

type Props = {
  id: string;
  createdAt: string;
  deliveryAt: string;
  title: string;
  message: string;
  status: Enums.NotificationStatus;
  onDelete(values: DeletePushNotificationParams): Promise<void>;
};

const NotificationsRow: FC<Props> = ({
  createdAt,
  deliveryAt,
  title,
  message,
  id,
  onDelete,
  status,
}) => {
  return (
    <Card>
      <Col>
        <ContentWrapper>
          <HighlightedText>{title}</HighlightedText>
          <Text>{message}</Text>
        </ContentWrapper>
      </Col>
      <Col>
        <Text>
          {formatHourMinuteSecondDate(new Date(deliveryAt || createdAt))}
        </Text>
      </Col>
      <Col>
        <Text>{formatHourMinuteSecondDate(new Date(createdAt))}</Text>
      </Col>
      <Col>
        <StatusIndicator status={parseEnumToPt(status, NotificationStatusPt)} />
      </Col>
      <ColDeleteButton>
        <DeleteButton
          id="delete_notification"
          onPress={() => onDelete({ id })}
          disabled={status === NotificationStatus.SUCCESS}
          transparent
        >
          <DeleteIcon icon={faTrash} />
        </DeleteButton>
      </ColDeleteButton>
    </Card>
  );
};

export default NotificationsRow;
