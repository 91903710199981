import { computed } from 'mobx';
import { UploadApi } from '~/api';
import { action, makeObservable, observable, persist } from '~/modules';

export default class UploadStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  uploadsList: ListResponse<FileUpload> = {};

  @persist('object')
  @observable
  currentFile: FileUpload = {} as FileUpload;

  @observable
  currentFileError: FileUploadError | undefined;

  @computed get getCurrentFileMetadata(): FileUploadMetaData[] {
    if (this.currentFileError?.recharge) {
      return this.currentFileError.recharge.metadata;
    }

    return [];
  }

  @computed get getCurrentFileExtraInfo(): FileUploadErrorRecharge | null {
    if (this.currentFileError?.recharge) {
      return this.currentFileError.recharge;
    }

    return null;
  }

  @action
  getFiles = async (values: GetFilesProps): Promise<void> => {
    this.uploadsList = await UploadApi.getFiles(values);
  };

  @action
  setCurrentFileError = (error: FileUploadError): void => {
    this.currentFileError = error;
  };

  @action
  setCurrentFile = (values: FileUpload): void => {
    this.currentFile = values;
  };

  @action
  uploadFile = async (values: UploadFile): Promise<void> => {
    const formData = new FormData();
    formData.append('file', values.file);
    formData.append('type', values.type);
    await UploadApi.uploadFile(formData);
  };

  @action
  validateFile = async (
    values: UploadFile,
  ): Promise<FileValidationResponse> => {
    const formData = new FormData();
    formData.append('file', values.file);
    formData.append('type', values.type);
    return UploadApi.validateFile(formData);
  };
}
