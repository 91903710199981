import { UploadRoles } from '../enums';

export const RolesValueFiles: SelectProps[] = [
  { label: 'Inativações', value: UploadRoles.INACTIVATE },
  { label: 'Recargas', value: UploadRoles.RECHARGE },
  {
    label: 'Agendamento de Limite Compra + Assaí',
    value: UploadRoles.LIMIT_UPDATE_SCHEDULING,
  },
];

export const controlFileColumnsRecharge = [
  'Código de erro',
  'Mensagem',
  'Informações adicionais',
];

export const controlFileColumnsIndividual = ['Código de erro', 'Mensagem', ''];

export const initialFileValidation = {
  failureCount: 0,
  importAmount: 0,
  importDate: '',
  successCount: 0,
  errorDetails: {},
};
