import { ProductType, ProductTypePt } from '../enums';

export const productsColumns = [
  'Nome do Produto',
  'Tipo',
  'Status',
  'Descrição',
  '',
];

export const uploadsColumns = [
  'Arquivo',
  'Tipo',
  // 'Valor total arquivo',
  'Status',
  'Data',
  '',
];

export const exportsColumns = ['Arquivo', 'Tipo', 'Status', 'Data', ''];

export const MCCActivateColumns = [
  'MCC',
  'Descrição',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
];

export const controlAccountsColumns = [
  'Nome',
  'Status',
  'Tipo de Conta',
  'Saldo',
];

export const controlAccountsColumnsAdd = [
  'Nome',
  'Status',
  'Tipo de Conta',
  'Saldo',
  'Selecionar',
];

export const MetadataColumns = ['Descrição', 'Valor', ''];

export const TypeProductValueSelect: SelectProps[] = [
  { label: ProductTypePt.BENEFITS, value: ProductType.BENEFITS },
  { label: ProductTypePt.BANKING, value: ProductType.BANKING },
  { label: ProductTypePt.CREDIT, value: ProductType.CREDIT },
];

export const positionsList = [1, 2, 3, 4, 5];
