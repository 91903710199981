import styled from 'styled-components';
import { Icon, getTheme, pxToRem } from '~/modules';
import Touchable from '../Touchable';
import Typography from '../Typography';

const brandPrimaryContrast = getTheme('brand.primary.contrast');
const brandPrimaryLight = getTheme('brand.primary.light');
const brandPrimaryMain = getTheme('brand.primary.main');
const backgroundZ4 = getTheme('background.z4');
const themeRadiusCard = getTheme('themeRadius.card');
const spacingSm = getTheme('spacing.sm');
const spacingLg = getTheme('spacing.lg');
const borderRadiusXl = getTheme('borderRadius.xl');

export const Row = styled.div`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const Card = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
  align-items: center;
  width: 100%;
  min-height: ${pxToRem(100)};
  padding: 0 ${spacingLg}px;

  background: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;

  & + & {
    margin-top: ${spacingSm}px;
  }
`;

export const Description = styled(Typography)``;

export const ButtonReceipt = styled(Touchable)`
  width: ${pxToRem(56)};
  height: ${pxToRem(56)};
  justify-content: center;
  align-items: center;
  border-radius: ${borderRadiusXl}px;
  background: linear-gradient(
    180deg,
    ${brandPrimaryLight} 0%,
    ${brandPrimaryMain} 100%
  );
`;

export const IconReceipt = styled(Icon)`
  font-size: ${pxToRem(24)};
  color: ${brandPrimaryContrast};
  font-weight: 900;
`;
