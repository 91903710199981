import { FC, useEffect } from 'react';
import { faPlus } from '~/assets';
import { FormikProps, useFormikContext } from '~/modules';
import {
  NotificationTypes,
  notificationTypes,
  oneHourInSeconds,
} from '~/utils';
import HeaderModal from '../HeaderModal';
import If from '../If';
import Select from '../Select';
import {
  Content,
  IconPlus,
  Input,
  NewNotificationButton,
  Wrapper,
} from './styles';

type Props = {
  isOpen: boolean;
  handleClose(): void;
};

const ModalCreateNotification: FC<Props> = ({ isOpen, handleClose }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    submitForm,
    setFieldValue,
    resetForm,
    setFieldTouched,
  }: FormikProps<{ type: string } & CreatePushNotificationParams> =
    useFormikContext();

  useEffect(() => {
    !isOpen && resetForm();
  }, [isOpen]);

  return (
    <Wrapper isOpen={isOpen}>
      <HeaderModal title="Criar notificação" onPressClose={handleClose} />
      <Content>
        <Select
          label="Tipo de notificação"
          placeholder="Selecione o tipo de notificação"
          id="type"
          name="type"
          error={touched.type && errors.type}
          onChange={({ name, value }) => setFieldValue(name, value)}
          value={values.type}
          options={notificationTypes}
        />
        <Input
          label="Titulo da notificação"
          placeholder="Digite o titulo da notificação"
          fullWidth
          id="title"
          name="title"
          error={touched.title && errors.title}
          value={values.title}
          onChange={(e) => {
            setFieldTouched('title', true, true);
            handleChange(e);
          }}
        />
        <Input
          label="Mensagem da notificação"
          placeholder="Digite a mensagem da notificação"
          fullWidth
          id="message"
          name="message"
          minRows={4}
          multiline
          error={touched.message && errors.message}
          value={values.message}
          onChange={(e) => {
            setFieldTouched('message', true, true);
            handleChange(e);
          }}
        />
        <If condition={values.type === NotificationTypes.SCHEDULED}>
          <Input
            label="Data de agendamento"
            fullWidth
            id="deliveryAt"
            name="deliveryAt"
            mask="99/99/9999 99h"
            inputProps={{ step: oneHourInSeconds }}
            error={touched.deliveryAt && errors.deliveryAt}
            value={values.deliveryAt}
            placeholder="dd/mm/aaaa HHh"
            onChange={(e) => {
              setFieldTouched('deliveryAt', true, true);
              handleChange(e);
            }}
          />
        </If>
        <NewNotificationButton
          id="btn_create_notification"
          onPress={submitForm}
        >
          <IconPlus icon={faPlus} />
          Criar notificação
        </NewNotificationButton>
      </Content>
    </Wrapper>
  );
};

export default ModalCreateNotification;
