import store from '~/stores';
import Keys from './keys';

const Storage = window.localStorage;
const {
  user: { setIsLogged },
  theme: { fetchClientProvider },
} = store;
export default class StorageService {
  static clearWholeStorage(): void {
    Storage.clear();
    setIsLogged(false);
    fetchClientProvider();
  }

  static getToken(): Token {
    const value = Storage.getItem(Keys.TOKEN);
    return value ? JSON.parse(value) : ({} as Token);
  }

  static setToken(token: Token): void {
    const item = JSON.stringify(token);
    Storage.setItem(Keys.TOKEN, item);
  }

  static getPublicKey(): string {
    return Storage.getItem(Keys.PUBLIC_KEY) || '';
  }

  static setPublicKey(key: string): void {
    Storage.setItem(Keys.PUBLIC_KEY, key);
  }

  static getClientId(): string {
    return Storage.getItem(Keys.CLIENT_ID) || '';
  }

  static setClientId(key: string): void {
    Storage.setItem(Keys.CLIENT_ID, key);
  }
}
